<!-- eslint-disable prettier/prettier -->
<template>
  <div id="app">
    <div class="header d-flex justify-content-between align-items-center mb-3">
      <!-- Back Button -->
      <a v-if="isEnterOTPPage" href="javascript:history.back()" class="back-button">
        <font-awesome-icon icon="fa-arrow-left" class="back-icon" />
      </a>

      <!-- Logo -->
      <div class="pmc-logo">
        <a href="https://parkingmgt.com" target="_blank">
          <img src="/pmc-logo.png" alt="Logo" />
        </a>
      </div>
    </div>

    <div class="box mb-4" v-if="isLoading">
      <div class="box-header">Loading</div>
      <div class="box-content">
        <div class="box-inner text-center">
          <LoadingState label="Loading your parking session" />
        </div>
      </div>
    </div>

    <router-view v-if="!isLoading" />

    <NetworkUnavailableBanner v-if="store.networkIssue" />
    <FooterLinks />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import LoadingState from "@/components/LoadingState.vue";
import NetworkUnavailableBanner from "@/components/NetworkUnavailableBanner.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import { useAppStore } from "@/stores/app";
import moment from "moment/moment";
import { useRoute } from "vue-router";
import { library } from "@fortawesome/fontawesome-svg-core"; // Import FontAwesome library
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"; // Import specific icon

// Add icons to the FontAwesome library
library.add(faArrowLeft);

export default defineComponent({
  name: "MobileView",
  setup() {
    const store = useAppStore();
    const route = useRoute();
    const isEnterOTPPage = computed(() => {
      return route.path === "/enterOTP";
    });
    return {
      isEnterOTPPage,
      store,
      sessionId: route.params?.sessionId as string,
    };
  },
  async created(): Promise<void> {
    await this.store.setupStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || "");

    // we're ready to go
    this.isLoading = false;
  },
  components: {
    LoadingState,
    NetworkUnavailableBanner,
    FooterLinks,
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
});
</script>

<style lang="scss">
$max-width: 450px;

#app {
  position: relative;
  width: 100vw;
  max-width: $max-width;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #ffffff;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
}

.back-button {
  display: inline-block;
  margin-left: 10px;
  color: #000;
}

.back-icon {
  width: 30px;
  height: 30px;
}
</style>

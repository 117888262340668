import { createRouter, createWebHistory } from "vue-router";
import { redirects } from "./redirects";
import MobileView from "@/views/MobileView.vue";

const routes = [
  // handle redirects
  ...redirects,

  {
    path: "/verification",
    name: "verification",
    component: MobileView,
    children: [
      {
        path: "",
        name: "loginVerification",
        component: () => import("@/views/gates/VendGatePhoneVerification.vue"),
      },
    ],
  },
  {
    path: "/enterOTP",
    name: "enterOTP",
    component: MobileView,
    children: [
      {
        path: "",
        name: "codeverification",
        component: () => import("@/views/gates/VendGateOTPVerification.vue"),
      },
    ],
  },
  {
    path: "/start",
    name: "start",
    component: MobileView,
    children: [
      // start a new self parking session
      {
        path: ":locationId",
        name: "startSelfParkingSession",
        component: () => import("@/views/Mobile/NewParkingSession.vue"),
      },
    ],
  },
  {
    path: "/v",
    name: "validation",
    component: MobileView,
    children: [
      // validation page
      // expecting a validation code
      {
        path: ":locationId/:validationCode",
        name: "ValidationPage",
        component: () => import("@/views/Mobile/ApplyValidation.vue"),
      },
    ],
  },
  {
    path: "/session",
    name: "session",
    component: MobileView,
    children: [
      // manage an existing parking session
      // expecting a sessionId
      {
        path: ":sessionId",
        name: "manageParkingSession",
        component: () => import("@/views/Mobile/ManageParkingSession.vue"),
      },
      {
        path: ":sessionId/pay",
        name: "Pay Parking Session",
        component: () => import("@/views/Mobile/PayParkingSession.vue"),
      },
      {
        path: ":sessionId/request",
        name: "Request Vehicle",
        component: () =>
          import("@/views/Mobile/RequestVehicle/RequestVehicle.vue"),
      },
      // customers are sent link to update guest room reservations
      {
        path: ":sessionId/billToRoom",
        name: "Bill to Room",
        component: () => import("@/views/Mobile/BillToRoom.vue"),
      },
    ],
  },
  {
    path: "/c",
    name: "conversion",
    component: MobileView,
    children: [
      {
        path: "pay",
        name: "Pay Notice",
        component: () => import("@/views/Mobile/PayParkingNotice.vue"),
      },
    ],
  },
  {
    path: "/permits",
    name: "permits",
    component: MobileView,
    children: [
      {
        path: "pay/:permitId/:invoiceNumber",
        name: "Pay Permit Invoice",
        component: () => import("@/views/Mobile/PayParkingInvoice.vue"),
      },
    ],
  },
  {
    path: "/search/venues/nissan-stadium-nashville-tn",
    redirect: "/venues/nissan-stadium-nashville-tn",
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/SearchView.vue"),
  },
  {
    path: "/venues",
    name: "venues",
    component: () => import("@/views/EventsView.vue"),
    children: [
      {
        path: ":slug",
        name: "venue-by-slug",
        props: true,
        component: () => import("@/views/Events/VenueView.vue"),
      },
      {
        path: ":slug/event/:eventSlug",
        name: "venue-events",
        props: true,
        component: () => import("@/views/Events/ReserveParkingView.vue"),
      },
      {
        path: ":slug/pass/:passId",
        name: "venue-passes",
        props: true,
        component: () => import("@/views/Events/ReservePassParking.vue"),
        // TODO: Bring this back once we sell out 2025 season passes.
        // redirect: (to: MatcherLocation) => ({
        //   path: `/venues/${to.params.slug}`,
        // }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
